import React from "react";
import Helmet from "react-helmet";
import "../styles/TermsandConditions.css";
import Footer from "../components/commonComponents/Footer";

import termsAndConditionImage from '../images/terms-and-conditions.png'

const TermsAndConditions = () => {
  return (
    <>
      <Helmet>
        <title>Terms And Conditions - Bachat Baazi | Group Buy Shopping App</title>
        <meta
          name="description"
          content="How to play Bachat Baazi? How to win Bachat Baazi auctions? What are Bachat Baazi 
          tokens? What are the rules of playing Bachat Baazi auction shopping game"
        />

        <meta
          name="keywords"
          content="Bachat Baazi auction India, BachatBaazi, How to play Bachat Baazi,
           How to participate in auction, Bachat Baazi game terms and conditions, Bachat Baazi rules, 
           Bachat baazi eligibility, Bachat Baazi rules of conduct, Bachat Baazi auction and site rules, 
           Bachat Baazi intellectual property and ownership, Bachat Baazi fees and payment, 
           Bachat Baazi shipping and delivery charges, Bachat Baazi disclaimers and warranty, 
           Bachat Baazi refund, Bachat Baazi return, Bachat Baazi Legal, Bachat Baazi Agreement, 
           Bachat Baazi Legality, Winning in auctions, winning in penny auctions, penny auctions real, 
           safe auction, trustworthy brand, trustworthy auctions, real players, safety guaranteed, 
           Bidding auctions in India, Penny auction India, Fair auctions in India, best auctions in India,
            Most famous auctions in India, Bidding, Bidding for products, bidding for lifestyle products, 
            Bidding contest, Bidding game India, Bidding app, Auction cars, Auction app India, Auction bid,
             auction token, Auction amount, e-auction legal in India, auction game legal in India, 
             online auction, winning auction, bidding auctions, bidding auctions in India,"
        />
      </Helmet>
      <div className="terms_and_conditions_outer">
        <div className="terms_and_conditions_title">
          <h1>Terms and Conditions</h1>
        </div>
        <div className="split">
          <div className="terms_and_conditions">
            {/* <div className="terms_and_conditions_title">
              <h1>Terms and Conditions</h1>
            </div> */}
            <section className="tnc_sections">
              <div>
                <h3 className="terms_heading">Generals</h3>
              </div>
              <p>
                The following Terms & Conditions shall apply to the relationship between Bachat Baazi (Revmax Technologies Private Limited, Grd Flr, 74/II, Techno Park, C Cross Road, Opp Gate No 2, Seepz, MIDC, Andheri East, Mumbai, Maharashtra, 400093) and the end user ("You" or "Your").
              </p>
              <ol>
                <li>
                  Your agreement to the terms & conditions means collectively the
                  below terms and conditions & the privacy policy.
                </li>
                <li>
                  Bachat Baazi means the <strong>"Website"</strong>,
                  www.bachatbaazi.com , microsites and any landing pages owned and
                  operated by Revmax Technologies Private Limited and Bachat Baazi
                  Mobile Application
                  <strong> ("App")</strong> the ("Company"). In these Terms "we",
                  "us", "our" refer to the "Company", Bachat Baazi and "you" and
                  "your" refer to the individual or <strong>Agency</strong> (the{" "}
                  <strong>"Agency"/"Agencies"</strong>) using the{" "}
                  <strong>Services</strong>.
                </li>
                <li>
                  By using Bachat Baazi services, you accept the following Terms and
                  Conditions. These Terms & Conditions constitute the entire
                  agreement between Bachat Baazi and You, and supersede and replace
                  all prior commitments, undertakings or representations, whether
                  written or oral, between You and Bachat Baazi with respect of Your
                  use of Bachat Baazi.
                </li>
                <li>
                  Bachat Baazi reserves the right to modify these Terms of Use and
                  Our Policies from time to time, by updating this document without
                  prior notification to You. Your continuing to use the{" "}
                  <strong>Services</strong> following the changes shall be construed
                  as Your acceptance of all such changes.
                </li>
              </ol>
            </section>

            <section className="tnc_sections">
              <div>
                <h3 className="terms_heading">Eligibility</h3>
              </div>
              <p>
                Bachat Baazi services are only available in India and items
                purchased can only be delivered within India. By using this Site or
                the Services found at this Site, you represent and warrant that you
                are (i) at least eighteen (18) years of age and/or (ii) otherwise
                recognized as being able to form legally binding contracts under
                applicable law.
              </p>
            </section>

            <section className="tnc_sections">
              <div>
                <h3 className="terms_heading">Rules Of Conduct</h3>
              </div>
              <ol>
                <li>
                  You are solely and fully responsible for all the user content,
                  conduct, postings and transmissions that are made under your user
                  Account and that you will do so in compliance with this Agreement
                  and all applicable local, state, national and international laws,
                  rules and regulations.
                </li>
                <li>
                  Your User Content, your photos, posting, information, content, any
                  publication, or any user content or Bachat Baazi’s use of the User
                  Content (or any portion thereof) on, through or by means of the
                  Service, will not infringe, or violate a third party's patent,
                  copyright, trademark, trade secret or other proprietary or
                  intellectual property rights, or result in the violation of any
                  applicable law or regulation.
                </li>
                <li>
                  You agree that by using the Service, you represent, warrant and
                  agree that you will not:
                  <ol type="a">
                    <li>
                      Infringe on the intellectual property, any patent, trademark,
                      copyright, moral rights or proprietary rights of any person,
                      Agency, Bachat Baazi or any other 3rd party.
                    </li>
                    <li>
                      Use manual or automated software, robots or any other means or
                      processes to participate, access, scrape or download any web pages, Content
                      or other Services.
                    </li>
                    <li>
                      Use the Service for any commercial or other purposes that are
                      not expressly permitted by these Terms.
                    </li>
                    <li>
                      Damage or interfere with the Service or exploit
                      vulnerabilities including but not limited to using Trojan
                      horses, denial of service, any harmful code or any kind of
                      virus or hacking tools or technologies.
                    </li>
                    <li>Violate any law or regulation, or any order of a court.</li>
                    <li>
                      {" "}
                      Use, distribute, collect or store information about any other
                      persons.
                    </li>
                    <li>
                      Use the Service to distribute unsolicited commercial email or
                      any other content.
                    </li>
                    <li>
                      Recruit or otherwise solicit any other User to join third
                      party services or websites that are competitive to BACHAT
                      BAAZI, without Bachat Baazi's prior written approval.
                    </li>
                    <li>Impersonate any person or entity.</li>
                    <li>
                      {" "}
                      Post, upload, publish, submit or transmit any Content that:
                      violates, or encourages any conduct that would violate, any
                      applicable law or regulation or would give rise to civil
                      liability; is fraudulent, false, misleading or deceptive; is
                      defamatory, obscene, pornographic, vulgar or offensive;
                      promotes discrimination, bigotry, racism, hatred, harassment
                      or harm against any individual or group; is violent or
                      threatening or promotes violence or actions that are
                      threatening to any other person; or promotes illegal or
                      harmful activities or substances.
                    </li>
                    <li>
                      Use, display, mirror or frame the Service, Bachat Baazi’s
                      name, any Bachat Baazi trademarks, logos or other proprietary
                      information, or the layout and design of any page or form
                      contained on a page, without Bachat Baazi 's express written
                      consent.
                    </li>
                    <li>
                      {" "}
                      Access, tamper with, or use non-public areas of the Service,
                      Bachat Baazi's computer systems, or the technical delivery
                      systems of the Service or any third-party provider system.
                    </li>
                    <li>
                      {" "}
                      Avoid, bypass, remove, deactivate, impair, descramble, or
                      otherwise circumvent any technological measure implemented by
                      Bachat Baazi or any of Bachat Baazi 's providers or any other
                      third party (including another user) to protect the Service or
                      Collective Content.
                    </li>
                    <li>
                      Forge any TCP/IP packet header or any part of the header
                      information in any email or newsgroup posting, or in any way
                      use the Service or Collective Content to send altered,
                      deceptive or false source-identifying information.
                    </li>
                    <li>
                      Attempt to decipher, decompile, disassemble or reverse
                      engineer any of the software used to provide the Service or
                      Collective Content.
                    </li>
                    <li>
                      Advocate, encourage, or assist any third party in doing any of
                      the foregoing.
                    </li>
                  </ol>
                </li>
                <li>
                  You acknowledge and agree that Bachat Baazi has the right to block
                  or suspend your use of the Services, if you breach these
                  provisions or our terms and conditions.
                </li>
                <li>You must only install the app from the Android Play Store.</li>
              </ol>
            </section>

            {/* <section className="tnc_sections">
              <div>
                <h3 className="terms_heading">Auction And Site Rules</h3>
              </div>
              <ol>
                <li>Only one account is allowed per user.</li>
                <li>
                  Bachat Baazi employees are not allowed to participate in the
                  auctions.
                </li>
                <li>
                  Bachat Baazi allows the users to delegate the responsibility of
                  bidding to the bot provided by Bachat Baazi (known as the
                  ‘Auto-bid’). These bots are available to all users and are free of
                  charge. The bots provided by the Bachat Baazi platform are simply
                  a way of delegation for users who do not wish to be active during
                  auctions. These bots place the bids based on the rules defined by
                  the user and do not use any special algorithm which cannot be
                  replicated by any human participating actively in the auctions.
                  Use of 3rd party software and any other bots (outside the bot
                  provided by Bachat Baazi platform) is strictly prohibited
                </li>
                <li>
                  Bid tokens are non-transferable and cannot be exchanged for money.
                  Users can purchase bid tokens via various payment methods. Bid
                  tokens purchased by the users do not have any expiry date. Bachat
                  Baazi may credit the user with free bid tokens from time to time
                  based on various offers and schemes. The free bid tokens credited
                  by Bachat Baazi may have an expiry date.
                </li>
                <li>
                  By submitting a bid, you are making a legal offer to buy the
                  product. A bid placed cannot be reversed. Once a bid is accepted,
                  you agree to complete the transaction and purchase the product at
                  the auction price within the allocated time. Failure to complete
                  the transaction within allocated time will result in expiry of
                  your right to purchase the product at the auction price and you
                  will not be entitled to any refunds.
                </li>
                <li>
                  If you do not win the auction, Bachat Baazi will return a portion
                  of your spent tokens above a certain threshold. Check you
                  eligibility for this refund after every auction.
                </li>
                <li>
                  Products images/photos shown on the website is for reference only.
                  the actual product may look different. However, the actual
                  product, and mode specified on the website will not be
                  compromised. you will get exactly what we have mentioned.
                </li>
                <li>
                  In very rare case, if we are not able to deliver you the product,
                  we will offer you alternative options after mutual understanding
                  with you.
                </li>
                <li>
                  In rare case, if due to technical reasons, our website or App
                  becomes unavailable, we reserve rights to cancel auction remained
                  live during that down-time. all used credits will be refunded to
                  use. winner on that auction will not be entitled to claim that
                  product. We will try to maintain our site 99.9% up, but due to
                  unavoidable technical faults from Hosting server, sometime site
                  may go down in some region or entire internet
                </li>
              </ol>
            </section> */}

            <section className="tnc_sections">
              <div>
                <h3 className="terms_heading">
                  Intellectual Property And Ownership
                </h3>
              </div>
              <ol>
                <li>
                  The Service and all its content are protected by copyrights,
                  trademarks and other intellectual property rights.
                </li>
                <li>
                  You acknowledge and agree that the Service and all its Content,
                  including all associated intellectual property rights, are the
                  exclusive property of Bachat Baazi and its licensors. You will not
                  remove, alter or obscure any copyright, graphics, photographs,
                  layout, service mark or other proprietary rights notices
                  incorporated in or accompanying the Service or Collective Content.
                </li>
              </ol>
            </section>

            <section className="tnc_sections">
              <div>
                <h3 className="terms_heading">Fees And Payments</h3>
              </div>
              <p>
                All prices quoted on Bachat Baazi Services are in Indian Rupees.
              </p>

              <h6>Products</h6>
              <p>
                Users can purchase the product they win in the auction at the auction
                price within the time period allocated. Users, if eligible, may also
                be able to purchase the product at the MRP, by clicking the Buy Now option. All
                payments are non-refundable.
              </p>
              <h6>Bids</h6>
              <p>
                Users can participate in auctions by bidding for the items.
                A registered user can buy auction bidding tokens at the
                prices displayed to the user on the screen. All payments made
                towards the purchase of auction bidding tokens are non-refundable.
              </p>
              <h6>Shipping and Delivery Charges</h6>
              <p>
                At present, Bachat Baazi does not levy any shipping or delivery charges.
                Bachat Baazi reserves the right to change the pricing or introduce any new fees or pricing at any time.
              </p>
              <h6>Convenience Fee</h6>
              <p>
                Bachat Baazi reserves the right to add or amend a convenience fee on each order.
                Any convenience fee will be displayed to the user on the screen before any order is placed.
              </p>
              <h6>Payments</h6>
              <p>
                Bachat Baazi offers various payment options including but not
                limited to UPI, NetBanking, Credit & Debit cards through its
                payment gateway partner/s. Bachat Baazi reserves the right to
                change its payment gateway partner/s and/or payment methods at its
                own discretion.
              </p>
              <p>
                You agree and acknowledge that the payment status shown to you by
                Bachat Baazi will be considered as true and final. You further agree
                and acknowledge that, in an event of a payment failure, where your
                account has been debited, Bachat Baazi has no control over the
                refund of your payment. You will require to follow up with
                your individual payment provider and/or the issuing bank. You may,
                however, contact Bachat Baazi for any information that you may need
                regarding your failed payment.
              </p>
              <p>
                In an event of failure to charge your payment method for full amount
                or if Bachat Baazi is notified of a chargeback, reversal, payment
                dispute or is charged a penalty, you agree that Bachat Baazi may
                pursue all available lawful remedies in order to obtain payment,
                including but not limited to, immediate cancellation, without notice
                to you, of any Services registered or renewed on your behalf.
              </p>
              <p>
                You acknowledge and agree that you may be charged Value Added Tax
                ("VAT"), Goods and Services Tax ("GST"), or other localized fees
                and/or taxes.
              </p>
              <p>
                You acknowledge and agree that where refunds are issued to your
                Payment Method, Bachat Baazi has absolutely no control over when the
                refund will be applied towards your Payment Method’s available
                balance. You further acknowledge and agree that the payment provider
                and/or individual issuing bank associated with your Payment Method
                establish and regulate the time frames for posting your refund, and
                that such refund posting time frames may range from five (5)
                business days to a full billing cycle, or longer.
              </p>
            </section>

            <section className="tnc_sections">
              <div>
                <h3 className="terms_heading">
                  Manufacturer's Warranty And Disclaimers
                </h3>
              </div>
              <p>
                Bachat Baazi does not manufacture or control any of the products or
                services offered on the Service. Hence it does not provide any
                warranties with respect to the product or services offered via its
                services. However, all products sold on Bachat Baazi are sourced
                from leading e-commerce providers and may be covered by the
                manufacturer's warranty included with the product. To obtain
                warranty service for defective products, please follow the
                instructions included in the manufacturer's warranty.
              </p>
            </section>

            <section className="auction_rules">
              <div>
                <h3 className="auction_rules_heading">
                  Other Terms
                </h3>
              </div>
              <p>
                One account per user: At Bachat Baazi, we want to create a fair environment for all users. Each individual is entitled to create and manage only one account on Bachat Baazi. We deploy diverse methods and technologies to identify users who have created or are managing 2 or more accounts. Creating multiple accounts is not a fair practice, and we take it extremely seriously. Upon identifying multiple account creators, Bachat Baazi may take one or more of the following steps:
              </p>
              <p>
                <ol>
                  <li>Suspend all accounts of such a user.</li>
                  <li>Make it harder in some other ways for such users to participate.</li>
                  <li>Not fulfil any orders placed by such a user. In case of repeat offences, we may start deducting an inconvenience fee of 20 - 30% from the orders placed by such users, before issuing any refunds.</li>
                </ol>
              </p>
              <p>
                Bachat Baazi’s assessment in such instances will be final and binding. We are not required to share the means through which we identify such users.
              </p>
              <p>
                Products images/photos shown on the website and the app are for reference only.
                The actual product may look different. However, the actual product, and mode specified
                will not be compromised. You will get exactly what we have listed.
              </p><p>

                In very rare cases, if we are not able to deliver you the product,
                we will offer you alternative options after mutual understanding with you.
              </p><p>

                We will try to maintain our site 99.9% up, but due to unavoidable technical
                faults from Hosting server, sometime site may go down in some region or entire internet.
                In rare cases, if due to technical reasons, our website or App becomes unavailable,
                we reserve the right to cancel the auctions that remained live during that down-time.
                All credits used will be refunded to the user. The auction winner will not be entitled
                to claim that product.
              </p><p>

                Auction bids are non-transferable and cannot be exchanged for money.
                Users can purchase bids via various payment methods.
                Bids purchased by the users do not have any expiry date.
                Bachat Baazi may credit the user with free bids from time to time based on various offers and schemes.
                The free bids credited by Bachat Baazi may have an expiry date.
              </p><p>

                By submitting a bid for an auction, you are making a legal offer to buy the product.
                A bid placed cannot be reversed. Once a bid is accepted,
                you agree to complete the transaction and purchase the product
                at the auction price within the allocated time. Failure to complete the
                transaction within the allocated time will result in the expiry of your right
                to purchase the product at the auction price, and you will not be entitled to any refunds.
              </p><p>

                If you do not win any auctions in a defined period,
                Bachat Baazi may return a portion of your paid bids above a certain threshold.
                You can check you eligibility by writing to us.
              </p><p>

                Bachat Baazi may in the future allow the users to delegate the responsibility
                of bidding to a bot provided by Bachat Baazi (known as the ‘Auto-Bid’).
                These bots will be made available to all users free of charge.
                The bots provided by the Bachat Baazi platform are simply a way of delegation for users
                who do not wish to be active during auctions.
                These bots will place the bid based on the rules defined by the user and will not use
                any special algorithm which cannot be replicated by any human participating actively in the auctions.
                Use of 3rd party software and any other bots (outside the bot provided by Bachat Baazi platform)
                is strictly prohibited and can lead to account closure. Bachat Baazi employees are not allowed to participate in the auctions.
              </p>
            </section>

            <section className="tnc_sections">
              <div>
                <h3 className="terms_heading">Links To Third Party Websites</h3>
              </div>
              <p>
                This Site and the Services found at this Site may contain links to
                third-party websites that are not owned or controlled by BACHAT
                BAAZI. Bachat Baazi assumes no responsibility for the content, terms
                and conditions, privacy policies, or practices of any third-party
                websites. By using this Site or the Services found at this Site, you
                expressly release Bachat Baazi from any and all liability arising
                from your use of any third-party website.
              </p>
            </section>

            <section className="tnc_sections">
              <div>
                <h3 className="terms_heading">Limitation Of Liability</h3>
              </div>
              <p>
                In no event shall Bachat Baazi, its officers, directors, employees,
                or agents be liable to you or any other person or entity for any
                direct, indirect, incidental, special, punitive, or consequential
                damages whatsoever, including any that may result from (i) the
                accuracy, completeness, or content of this site, (ii) the accuracy,
                completeness, or content of any sites linked (through hyperlinks,
                banner advertising or otherwise) to this site, (iii) the services
                found at this site or any sites linked (through hyperlinks, banner
                advertising or otherwise) to this site, (iv) personal injury or
                property damage of any nature whatsoever, (v) third-party conduct of
                any nature whatsoever, (vi) any unauthorized access to or use of our
                servers and/or any and all content, personal information, financial
                information or other information and data stored therein, (vii) any
                interruption or cessation of services to or from this site or any
                sites linked (through hyperlinks, banner advertising or otherwise)
                to this site, (viii) any viruses, worms, bugs, trojan horses, or the
                like, which may be transmitted to or from this site or any sites
                linked (through hyperlinks, banner advertising or otherwise) to this
                site, (ix) any user content or content that is defamatory,
                harassing, abusive, harmful to minors or any protected class,
                pornographic, “x-rated”, obscene or otherwise objectionable, and/or
                (x) any loss or damage of any kind incurred as a result of your use
                of this site or the services found at this site, whether based on
                warranty, contract, tort, or any other legal or equitable theory,
                and whether or not Bachat Baazi is advised of the possibility of
                such damages.
              </p>
            </section>

            <section className="tnc_sections">
              <div>
                <h3 className="terms_heading">Indemnity</h3>
              </div>
              <p>
                You agree to defend, hold harmless, and indemnify Bachat Baazi, its
                affiliates, licensors, and service providers, and its and their
                respective officers, directors, employees, contractors, agents,
                licensors, suppliers, successors, and assigns from and against any
                and all claims, actions, proceedings, suits, liabilities, damages,
                settlements, penalties, fines, costs, or expenses (including,
                without limitation, reasonable attorneys' fees, court costs, and
                other litigation expenses) arising out of or relating to (a) your
                violation or breach of any term, condition, representation or
                warranty of these Terms; (b) your improper or illegal use of the
                Services; (c) your violation, alleged violation, or misappropriation
                of any intellectual property right (including, without limitation,
                trademark, copyright, patent, trade secrets) or non-proprietary
                right of a third party; or (d) your use or access of the Services,
                or access by anyone accessing the Services using your username and
                password.
              </p>
            </section>

            <section className="tnc_sections">
              <div>
                <h3 className="terms_heading">Termination of Services</h3>
              </div>
              <ol>
                <li>
                  We reserve the right to, in our discretion and without liability
                  to you, with or without any cause and without any prior notice, at
                  any time terminate these terms or your access to Service and
                  suspend your user account.
                </li>
                <li>
                  You may cancel your User account at any time by contacting BACHAT
                  BAAZI. Please note that if your User account is cancelled, we do
                  not have an obligation to delete or return to you any Content you
                  have posted to the Service, including, but not limited to, photos,
                  personal information, any reviews or feedback etc.
                </li>
              </ol>
            </section>

            <section className="tnc_sections">
              <div>
                <h3 className="terms_heading">
                  Refund, Returns And Cancellation Policy
                </h3>
              </div>
              <ol>
                <li>
                  The product purchased in auctions cannot be returned or exchanged
                  except in scenario when the product is received in a broken or
                  faulty condition or when incorrect product is delivered.
                </li>
                <li>
                  The auction bidding tokens that you have purchased cannot be refunded. You can
                  utilize them to bid for your favourite products. In case of any
                  issue regarding bids purchased, you can get in touch with our
                  Customer Care. We will try and find the best possible solution for you.
                </li>

                <li>The product ordered cannot be cancelled</li>
              </ol>
            </section>

            <section className="tnc_sections">
              <div>
                <h3 className="terms_heading">Disclaimer</h3>
              </div>
              <ol>
                <li>
                  You acknowledge and agree that Bachat Baazi does not review,
                  endorse, recommend, verify, evaluate, warrant or guarantee any
                  statement, opinion, response, advice, prediction, recommendation,
                  information or content provided by any user. Nothing contained in
                  these terms shall be considered as a referral, endorsement,
                  recommendation, verification, warranty or guarantee with respect
                  to (a) any user; (b) any Agency; the validity, accuracy,
                  availability, completeness, safety, legality, quality or
                  applicability of any content or anything otherwise contained in or
                  made available on the service or any other forum.
                </li>
                <li>
                  If you choose to use the service, you do so at your sole risk. You
                  are solely responsible for all of your communications and
                  interactions with any Agencies, with other persons or other users
                  whom you interact or communicate with as a result of your use
                  of the Service. You agree to take reasonable precautions in all
                  communications and interactions with other users and with other
                  persons with whom you communicate or interact as a result of your
                  use of the Service. You acknowledge and agree to indemnify and
                  hold Bachat Baazi harmless in connection with any claim and any
                  damages or expenses arising from your use of the Service.
                </li>
              </ol>
            </section>

            <section className="tnc_sections">
              <div>
                <h3 className="terms_heading">Applicable Law</h3>
              </div>
              <p>
                This Agreement shall be governed by and construed in accordance with
                the laws in India, whichever is applicable, without regard to
                conflict of laws principles. You agree that any action relating to
                or arising out of this Agreement shall be brought in the state or
                federal courts of India Gujarat state, and you hereby consent to
                (and waive all defenses of lack of personal jurisdiction and forum
                non conveniens with respect to) jurisdiction and venue in the state
                and federal courts of India, Gujarat. You agree to waive the right
                to trial by jury in any action or preceding that takes place
                relating to or arising out of this Agreement.
              </p>
            </section>

            <section className="tnc_sections">
              <div>
                <h3 className="terms_heading">Contact Information</h3>
              </div>
              <p>
                If you have any questions about this Agreement, please contact us by
                email or regular mail at the following address:
              </p>
              <p>Address</p>
              <p>
                Revmax Technologies Private Limited, 697,  Shahabad Daulatpur, Near
                DTU,  DELHI - 110042
              </p>
              <p>Email</p>
              <p>support@bachatbaazi.com</p>
            </section>
          </div>
          <div className="terms_and_conditions_image">
            <img src={termsAndConditionImage} alt="terms_and_conditions_image" />
          </div>
        </div>
      </div>


      <Footer />
    </>
  );
};

export default TermsAndConditions;
