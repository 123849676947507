import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import Footer from '../components/commonComponents/Footer';
import cancellationImage from '../images/cancellation-policy.png'

import '../styles/CancellationPolicy.css'

function CancellationPolicy(){

  return (
    <Fragment>
      <Helmet>
        <title>Cancellation Policy - Bachat Baazi | Group Buy Shopping App</title>
        <meta
          name="description"
          content="How to play Bachat Baazi? How to win Bachat Baazi auctions? What are Bachat Baazi 
          tokens? What are the rules of playing Bachat Baazi auction shopping game"
        />

        <meta
          name="keywords"
          content="Bachat Baazi auction India, BachatBaazi, How to play Bachat Baazi,
           How to participate in auction, Bachat Baazi game terms and conditions, Bachat Baazi rules, 
           Bachat baazi eligibility, Bachat Baazi rules of conduct, Bachat Baazi auction and site rules, 
           Bachat Baazi intellectual property and ownership, Bachat Baazi fees and payment, 
           Bachat Baazi shipping and delivery charges, Bachat Baazi disclaimers and warranty, 
           Bachat Baazi refund, Bachat Baazi return, Bachat Baazi Legal, Bachat Baazi Agreement, 
           Bachat Baazi Legality, Winning in auctions, winning in penny auctions, penny auctions real, 
           safe auction, trustworthy brand, trustworthy auctions, real players, safety guaranteed, 
           Bidding auctions in India, Penny auction India, Fair auctions in India, best auctions in India,
            Most famous auctions in India, Bidding, Bidding for products, bidding for lifestyle products, 
            Bidding contest, Bidding game India, Bidding app, Auction cars, Auction app India, Auction bid,
             auction token, Auction amount, e-auction legal in India, auction game legal in India, 
             online auction, winning auction, bidding auctions, bidding auctions in India,"
        />
      </Helmet>
      <div className="cancellation-policy-outer">
        <h1>
          Cancellation Policy
        </h1>
        <div className="split">
          <div className="cancellation-policy">
            <h2>
              Non-Customer Cancellation
            </h2>
            <p>
              Bachat Baazi reserves the right to cancel any order if -
            </p>
            <ul>
              <li>The address provided by you is either wrong or falls outside the delivery zone.</li>
              <li>There is lack of information, direction or authorization from you at the time of delivery.</li>
              <li>Some or all the items ordered by you are unavailable at the time the order is being processed.</li>
            </ul>
            <h2>
              Non-Customer Cancellation
            </h2>
            <ul>
              <li>An order cannot be cancelled once the item has been dispatched for delivery in which case you will have to return the items to Us after delivery by following Our Return Policy. Kindly refer to the return policy. If there is an exceptional circumstance under which you need to cancel your purchase, please contact Us.</li>
              <li>
                <p>
                  Upon cancellation, you will be notified of the same, and the refund for the cancellation will be made in the following manner:
                </p>
                <ul>
                  <li>
                    <p><strong>Electronic payment</strong> (credit card, debit card or net banking) refunds are processed within 2-4 business days whereby you will need to update following information to enable Us to process a refund to your account:</p>
                    <ul>
                      <li>Bank Account Number</li>
                      <li>IFSC Code</li>
                      <li>Account Holder's Name</li>
                      <li>Bank Branch</li>
                      <li>Bank Name</li>
                    </ul>
                  </li>
                  <li> <strong>Mixed Payment</strong> (credit card, gift cards or credit points) – The refund amount shall be paid through the same mode of payment. The gift card amount shall be added to your account under points and can be used for redemption towards future purchases.</li>
                </ul>
              </li>
              <li>
                <p>
                  Upon cancellation, your refund (if payment made by electronic mode or mixed payment methods) will be processed within the following timelines:
                </p>
                <table>
                  <thead>
                    <tr>
                      <th>Payment Method</th>
                      <th>TAT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td> Debit/ Credit Card </td>
                      <td>5-7 business days</td>
                    </tr>
                    <tr>
                      <td>Net Banking</td>
                      <td>5-7 business days</td>
                    </tr>
                    <tr>
                      <td>Wallet</td>
                      <td>0-3 business days</td>
                    </tr>
                    <tr>
                      <td>UPI</td>
                      <td>2-4 business days</td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <li>
                <strong>Fine Print</strong>
                <ul>
                  <li>Bachat Baazi will have the authority to make the final decision on the refund amount and when it is generated.</li>
                  <li>Refunds to happen through the same mode by which the payment was made, however, you may want to request for a change to credit/ reward points.</li>
                  <li>  If your refund doesn't appear in ‘Your Account’, and the processing time for your payment method has passed, contact Us for further assistance.</li>
                </ul>
              </li>
              <li>
                <strong>Expired Payment Method</strong>
                <p>
                  If the original payment method you used to make the payment (credit/ debit card) is no longer valid, We may issue the refund through a demand draft.
              </p>
                <p>
                  When We receive a payment failure notice from your bank, We will send you an e-mail asking you to call us. When you call Us back, We will collect your name and the address where you want the demand draft to be sent, and proceed with the refund.
              </p>
              </li>
            </ul>
            <h2>
              STEPS TO CANCEL
          </h2>
            <p>
              To cancel any order placed by you on the Bachat Baazi App, first login with the same ID through which the order was placed, and after that you may write to Us. The order can only be cancelled before the dispatch of the products. Once the order is out for delivery, the customer can not cancel the order through Bachat Baazi App. In case the customer wants to cancel the order at the time of delivery of products then the same can be mark as cancelled at the same time by the delivery associate.
          </p>
          </div>
          <div className="cancellation-policy-image">
            <img src={cancellationImage} alt="cancellation-policy" />
          </div>
        </div>

      </div>
      <Footer />
    </Fragment>
  );
}

export default CancellationPolicy;