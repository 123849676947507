import React from "react";
import playwhite from "../../images/google_play_v1.png";
import "../../styles/commonComponentsStyles/Googleplay.css";

const Googleplay = ({ className }) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://play.google.com/store/apps/details?id=com.bachatbaazi&utm_source=website&utm_medium=website"
    >
      <img src={playwhite} style={{width: '100%'}} alt="google play" />

      {/* <div id="google-play" className={className}>

        <span id="playtext">Google Play</span>
      </div> */}
    </a>
  );
};

export default Googleplay;
