import React, { Fragment }from 'react';
import Helmet from 'react-helmet';
import Footer from "../components/commonComponents/Footer";
import contactUsImage from '../images/contact-us.png'

import '../styles/ContactUs.css'

function DeleteAccount(){
  return (
    <Fragment>
      <Helmet>
        <title>Delete Account - Bachat Baazi | Group Buy Shopping App</title>
        <meta
          name="description"
          content="How to play Bachat Baazi? How to win Bachat Baazi auctions? What are Bachat Baazi 
          tokens? What are the rules of playing Bachat Baazi auction shopping game"
        />

        <meta
          name="keywords"
          content="Bachat Baazi auction India, BachatBaazi, How to play Bachat Baazi,
           How to participate in auction, Bachat Baazi game terms and conditions, Bachat Baazi rules, 
           Bachat baazi eligibility, Bachat Baazi rules of conduct, Bachat Baazi auction and site rules, 
           Bachat Baazi intellectual property and ownership, Bachat Baazi fees and payment, 
           Bachat Baazi shipping and delivery charges, Bachat Baazi disclaimers and warranty, 
           Bachat Baazi refund, Bachat Baazi return, Bachat Baazi Legal, Bachat Baazi Agreement, 
           Bachat Baazi Legality, Winning in auctions, winning in penny auctions, penny auctions real, 
           safe auction, trustworthy brand, trustworthy auctions, real players, safety guaranteed, 
           Bidding auctions in India, Penny auction India, Fair auctions in India, best auctions in India,
            Most famous auctions in India, Bidding, Bidding for products, bidding for lifestyle products, 
            Bidding contest, Bidding game India, Bidding app, Auction cars, Auction app India, Auction bid,
             auction token, Auction amount, e-auction legal in India, auction game legal in India, 
             online auction, winning auction, bidding auctions, bidding auctions in India,"
        />
      </Helmet>
      <div className="contact-us-outer">
        <h1> <b>How to delete account</b></h1>
        <div className="split">
          <div className="contact-us">
            <h2>Have a question about how to delete from Bachat Baazi APP?</h2>
            <p>
              If you have a question about how to delete, send us an email at <a className="emphasis" href="mailto: support@bachatbaazi.com">support@bachatbaazi.com</a> or Whatsapp us at <span className="emphasis">9310751612</span>
          </p>
            <div className="other-queries">
              <h3>For other queries</h3>
              <article>
                <h2>Business &amp; Partnerships</h2>
                <span>marketing@bachatbaazi.com</span>
              </article>
              <article>
                <h2>Technical</h2>
                <span>dev@bachatbaazi.com</span>
              </article>
            </div>
            <address className="address-section">
              <h2>Registered Office:</h2>
              <p>Revmax Technologies Private Limited</p>
              <p>B-501, Floor-5, B Wing, Lodha Bellissimo,N M Joshi, Marg, Apolo Mill Compound Mahalaxmi, </p>
              <p>Jacob Circle, Mumbai, Mumbai City, Maharashtra, India, 400011</p>
            </address>
          </div>
          <div className="contact-us-image">
            <img src={contactUsImage} alt="contact-us"/>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default DeleteAccount;