import React, { useEffect } from 'react';
import {Switch, Route, Redirect} from 'react-router-dom'
import ReactGa from "react-ga"
import {TRACKING_ID} from "./config"
import './App.css';
import Navbar from './components/commonComponents/Navbar';
import Home from './pages/HomePage'
// import Auctions from "./components/auctionComponents/Auctions"
// import ProductDetails from "./components/productDetailsComponents/ProductDetail"
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import FAQ from './pages/FAQ';
import DeleteAccount from './pages/DeleteAccount';
import ContactUs from './pages/ContactUs';
import CancellationPolicy from './pages/CancellationPolicy';
import Returns from './pages/Returns';
import Refunds from './pages/Refunds';
import GroupBuy from './pages/GroupBuy';
import ScrollToTop from './components/ScrollToTop';
import WorkingGroupBuy from './pages/WorkingGroupBuy';
import WorkingAuctions from './pages/WorkingAuctions';
import GroupBuys from './pages/GroupBuys';
import Auctions from './pages/Auctions';
import DownForMaintainence from './pages/DownFormaintenence';
  
function App() {

  useEffect(()=>{
    ReactGa.initialize(TRACKING_ID);
    ReactGa.pageview(window.location.pathname + window.location.search)
  })

  return (
    <ScrollToTop>
    <div className="App">
      <Navbar/>
      <Switch>
        {/* <Route exact path="/" component={DownForMaintainence}/> */}
        <Route exact path="/" component={Home}/>
        {/* <Route exact path="/groupbuy/:id" component={GroupBuy}/> */}
        {/* <Route exact path="/groupbuys" component={GroupBuys}/> */}
        {/* <Route exact path="/auctions" component={Auctions}/> */}
        {/* <Route exact path="/auctions" component={Auctions}/> */}
        {/* <Route exact path="/product/:productId" component={ProductDetails}/> */}
        <Route exact path="/contactus" component={ContactUs}/>
        <Route exact path="/cancellationpolicy" component={CancellationPolicy}/>
        <Route exact path="/returns" component={Returns}/>
        <Route exact path="/refunds" component={Refunds}/>
        <Route exact path="/termsandconditions" component={TermsAndConditions}/>
        <Route exact path="/privacypolicy" component={PrivacyPolicy}/>
        <Route exact path="/faq" component={FAQ}/>
        <Route exact path="/delete-account" component={DeleteAccount}/>
        {/* <Route exact path="/workingGroupBuy" component={WorkingGroupBuy}/> */}
        {/* <Route exact path="/workingAuctions" component={WorkingAuctions}/> */}

        <Redirect to="/"/>
      </Switch>
    </div>
    </ScrollToTop>
  );
}

export default App;
