import React, { useState } from "react";
import { Button, Image, Modal } from "semantic-ui-react";

import "../../styles/commonComponentsStyles/Navbar.css";
// import Googleplay from "./Googleplay";
import { Link } from "react-router-dom";
import logo from "../../images/color_logo.png";
import UserDetailsForm from "../modals/UserDetailsForm";

const Navbar = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div id="navbar">
        <Image
          style={{ height: 40, width: "auto" }}
          src={logo}
        />
        <div className="nav-items-contianer">
          <Link to="/">
            <p>Home</p>
          </Link>
          {/* <Link to="/workingAuctions">
            <p>How it works</p>
          </Link> */}
          <Link to="/privacypolicy">
            <p>Privacy policy</p>
          </Link>
          <Link to="/faq">
            <p>FAQ</p>
          </Link>
        </div>
      </div>
      {/* <UserDetailsForm showModal={showModal} setShowModal={setShowModal} /> */}
    </>
  );
};

export default Navbar;
