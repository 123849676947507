import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Icon, Loader, Dimmer } from 'semantic-ui-react';
import { Carousel } from 'react-responsive-carousel';
import Countdown from '../components/Countdown';
import InstallAppModal from '../components/modals/InstallAppModal';
import Footer from "../components/commonComponents/Footer";
import useFetchGroupBuyProduct from '../hooks/useFetchGroupBuyProduct';
import { BBUPLOADS_URI } from '../config';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import bbCoin from '../images/BBCoin.png';

import '../styles/GroupBuy.css'

function GroupBy(){
  const [isModalOpen, setModalOpen] = useState(false);

  const { id } = useParams();
  const { isLoading, data, error } = useFetchGroupBuyProduct(id);
  
  if(isLoading) {
    return (
      <div>
        <Dimmer active inverted>
          <Loader inverted size="huge" content='Loading' />
        </Dimmer>
      </div>
    )
  }
  
  if(error !== null){
    return (
      <h1>Error loading the page</h1>
    );
  }
  
  let percentJoined = (data.usersJoined/data.milestone)*100;
  
  const getCorusel = () => (
    <Carousel>
      {
        data.product.images.map((img) => (
          <div key={img.avatar}>
            <img src={`${BBUPLOADS_URI}/${img.avatar}`} alt={img.avatar} />
          </div>
        ))
      }
    </Carousel>
  );
  
  return (
    <Fragment>
      <div className="groupby-container">
        <div className="product-image-container desktop">
          {getCorusel()}
        </div>
        <div className="content">
          <div className="product-title-container">
            <h1 className="product-title">
              { data.product.name }
            </h1>
            <Icon name="heart" />
          </div>
          <div className="rating-row">
            <div className="rating-container">
              <StarRating rating={ data.product.rating }/>
              <span className="rating orange">{ data.product.rating.toFixed(2) }</span>
              <span className="num-ratings">{ data.product.noOfRatings } Ratings</span>
            </div>
            <span className="views">
              <Icon name="eye" />
              {data.views} Views
            </span>
          </div>
          <div className="product-image-container">
            {getCorusel()}
          </div>
          <hr />
          <div className="price-container">
            <div className="price">
              Price:
              <div>
                <span className="new-price">Rs. {data.discountedPrice}</span>
                <span className="old-price">{data.price}</span>
              </div>
            </div>
            <div className="countdown">
              Closes in
              <Countdown date={data.expiresAt} />
            </div>
          </div>
          <a href="#" className="join-btn" onClick={() => setModalOpen(true)}>Join Now</a>
          <hr />
          <div className="milestone-container">
            <h2>Milestones</h2>
            <h3>{data.usersJoined} People have joined</h3>
            <ProgressBar value={percentJoined}/>
          </div>
          <hr />
          <div className="token-reward">
            <img src={bbCoin} alt="bbCoin" className="bb-coin" />
            <p>
              On making of payment of <b>Rs. {data.discountedPrice}</b> you will earn <b>{Math.ceil(data.discountedPrice/5)} BB tokens</b> as a reward
            </p>
          </div>
          <hr />
          <div className="share-container">
            <button className="share-btn">
              Share
            </button>
            <p>
              Share this product with your friends to earn more discount
            </p>
          </div>
          <hr />
          <div className="description-container">
            <h2>Product Description</h2>
            <h1>{ data.product.name }</h1>
            <p>{ data.product.description }</p>
          </div>
          <ReviewContainer overallRating={ data.product.rating } numRatings={ data.product.noOfRatings }>
            {
              data.product.reviews?.map((review, index) => (
                <ReviewContainer.Review
                  key={`${index}-${review.name}`}
                  author={review.name}
                  rating={review.rating}
                  title={review.title}
                  reviewText={review.review}
                />
              ))
            }
          </ReviewContainer>
        </div>
      </div>
      <Footer/>
      <InstallAppModal open={isModalOpen} onClose={() => setModalOpen(false)}>
        To join this Group Buy deal, please install the app clicking below and start saving
      </InstallAppModal>
    </Fragment>
  );
  
}

function ProgressBar({ value }) {
  return (
    <div className="progress-bar-container">
      <div className="progress-bar">
        <div className="bar" style={{width: `${value}%`}}></div>
        <span 
          className="indicator" 
          style={{left: `calc(${value}% - var(--indicator-width) / 2`}}
        >
          {value.toFixed(1)}
        </span>
      </div>
      <div className="progress-label">
        <span>0%</span>
        <span>100%</span>
      </div>
    </div>
  );
}

function StarRating({ rating=4 }) {
  
  let ratingFloor = Math.floor(rating);
  let ratingArray = [...Array(ratingFloor).fill(1), ...Array(5-ratingFloor).fill(0)];

  return (
    <ul className="star-rating">
      {ratingArray.map((val, index) => (
        <li className="rating-star" key={index}>
          <Icon name={`star${val===1?'':' outline'}`} />
        </li>
      ))}
    </ul>
  );
}

function ReviewContainer({ children, overallRating, numRatings }){
  return (
    <div className="review-container">
      <h2>
        Customer Reviews
      </h2>
      <div className="rating-container left-justified">
        <StarRating rating={ overallRating } />
        <span className="rating purple">{ overallRating?.toFixed(2) }</span>
        <span className="num-ratings">{ numRatings } Ratings</span>
      </div>
      <ul className="reviews">
        { children }
      </ul>
    </div>
  );
}

ReviewContainer.Review = function({ rating, reviewText, author, title }){
  return (
    <li className="review">
      <div className="reviewer-name-container">
        <span className="avatar"></span>
        <div>
          <span className="reviewer-name">{ author }</span>
          <div className="rating-container">
            <StarRating rating={rating} />
          </div>
        </div>
      </div>
      <h2>{ title }</h2>
      <p className="review-text">
        { reviewText }
      </p>
    </li>
  );
}
ReviewContainer.Review.displayName = 'Review';



export default GroupBy;