import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import Footer from '../components/commonComponents/Footer';
import returnsImage from '../images/returns.png';

import '../styles/Returns.css'

function Returns(){

  return (
    <Fragment>
      <Helmet>
        <title>Returns - Bachat Baazi | Group Buy Shopping App</title>
        <meta
          name="description"
          content="How to play Bachat Baazi? How to win Bachat Baazi auctions? What are Bachat Baazi 
          tokens? What are the rules of playing Bachat Baazi auction shopping game"
        />

        <meta
          name="keywords"
          content="Bachat Baazi auction India, BachatBaazi, How to play Bachat Baazi,
           How to participate in auction, Bachat Baazi game terms and conditions, Bachat Baazi rules, 
           Bachat baazi eligibility, Bachat Baazi rules of conduct, Bachat Baazi auction and site rules, 
           Bachat Baazi intellectual property and ownership, Bachat Baazi fees and payment, 
           Bachat Baazi shipping and delivery charges, Bachat Baazi disclaimers and warranty, 
           Bachat Baazi refund, Bachat Baazi return, Bachat Baazi Legal, Bachat Baazi Agreement, 
           Bachat Baazi Legality, Winning in auctions, winning in penny auctions, penny auctions real, 
           safe auction, trustworthy brand, trustworthy auctions, real players, safety guaranteed, 
           Bidding auctions in India, Penny auction India, Fair auctions in India, best auctions in India,
            Most famous auctions in India, Bidding, Bidding for products, bidding for lifestyle products, 
            Bidding contest, Bidding game India, Bidding app, Auction cars, Auction app India, Auction bid,
             auction token, Auction amount, e-auction legal in India, auction game legal in India, 
             online auction, winning auction, bidding auctions, bidding auctions in India,"
        />
      </Helmet>
      <div className="returns-outer">
        <h1>
          Returns
        </h1>
        <div className="split">
          <div className="returns">
            <p>
              Returns at Bachat Baazi are easy and customer friendly. If the product is defective, damaged, wrong item sent or item missing, you need to follow the below mentioned guidelines and arrange for return pick-up within 2 Business days:
            </p>
            <ul>
              <li>
                <strong>Return Pick-up</strong>
                <ul>
                  <li>Write to Our customer service representative at the email address provided in the app, and We shall guide you as to the next steps.</li>
                  <li>Kindly keep a copy of the invoice highlighting the items being returned.</li>
                  <li>Return shipments are trackable. Refer to the tracking ID mentioned on the receipt at the time of return.</li>
                  <li>Once We receive the product back, you will receive an email confirmation about the same. It usually takes 24-48 hours for Us to receive the item back.</li>
                  <li>If the return pick-up option is not available in your area or if you choose to return the item yourself. Include the required return documentation with your package. You can return the package using any courier/ postal service of your choice.</li>
                  <li>If the return pick-up option is not available in your area or if you choose to return the item yourself. Include the required return documentation with your package. You can return the package using any courier/ postal service of your choice.</li>
                  <li>
                    <p>
                      If you wish to exchange the products, you must submit the following at the time of exchange:
                    </p>
                    <ul>
                      <li>Product Return Form</li>
                      <li>Reason for Return</li>
                      <li>Copy of Invoice</li>
                      <li>Copy of Customer Order Receipt</li>
                      <li>Products to be returned</li>
                    </ul>
                  </li>
                </ul>
                <p>The aforesaid documents are required to be submitted at the time of return pick-up.</p>
              </li>
              <li>
                <strong>Fine print</strong>
                <ul>
                  <li>Certain items are non-returnable, an icon on the item description page would specify that the item can’t be returned. Also please read the Refunds policy.</li>
                  <li>The product must be returned in the original condition they were received in with all the accompanying accessories.</li>
                  <li>Replacement/ refund would only be initiated once the return is received and been inspected for defect/ damage.</li>
                  <li>Items that you no longer need must be returned in new and unopened condition with all the original packing, tags, inbox literature, warranty/ guarantee card, freebies and accessories.</li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="returns-image">
            <img src={returnsImage} alt="returns"/>
          </div>
        </div>
      </div>
      <Footer/>
    </Fragment>
  );
}

export default Returns;