import React from "react";
import Helmet from "react-helmet";
import "../styles/PrivacyPolicy.css";
import Footer from "../components/commonComponents/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
      <title>Privacy Policy - Bachat Baazi | Group Buy Shopping App</title>

        <meta
          name="description"
          content="privacy policy of Bachat Baazi online auction in India"
        />

        <meta
          name="keywords"
          content="Bachat Baazi auction India, Bachat Baazi Privacy policy, 
          Bachat Baazi data, Bachat Baazi Use of Data, Bachat Baazi information Security, 
          BachatBaazi,  Cookies, BachatBaazi, Bachat Baazi app, BachatBaazi app, Data policy 
          for BachatBaazi, Auction policy for BachatBaazi, Auction policy for Bachat Baazi, 
          How to play Bachat Baazi, How to participate in auction, Winning in auctions, 
          winning in penny auctions, penny auctions real, safe auction, trustworthy brand, 
          trustworthy auctions, real players, safety guaranteed, Bidding auctions in India, 
          Penny auction India, Fair auctions in India, best auctions in India, Most famous 
          auctions in India, Bidding, Bidding for products, bidding for lifestyle products,
           Bidding contest, Bidding game India, Bidding app, Auction cars, Auction app India,
            Auction bid, auction token, Auction amount, Online shopping sites in India, 
            trustworthy auction, fair auction, real auction, e-auction, online auction, 
            winning auction, bidding auctions, bidding auctions in India, questions on auctions, 
            customer support auction India, customer support Bachat Baazi"
        />
      </Helmet>
      <div className="privacy_policy">
        <div className="privacy_policy_title">
          <h1>Privacy Policy</h1>
        </div>
        <section className="privacy_sections">
          <div>
            <h3 className="privacy_heading">1. Generals</h3>
          </div>
          <p>
            The BACHAT BAAZI website www.bachatbaazi.com, BACHAT BAAZI
            microsites and BACHAT BAAZI landing pages, collectively called as
            Website, and the BACHAT BAAZI Mobile App is owned by and operated by
            Revmax Technologies Private Limited (Grd Flr, 74/II, Techno Park, C Cross Road, Opp Gate No 2, 
            Seepz, MIDC, Andheri East, Mumbai, Maharashtra, 400093, India).
            BACHAT BAAZI provides services to its users through the website,
            mobile app, SMS, email, calls, databases, interactive voice
            response services and other online and offline channels.
          </p>
          <p>
            This privacy policy sets out how BACHAT BAAZI collects, shares, uses
            and protects your personal information whether by using BACHAT BAAZI
            App; information submitted to us on applications or other forms and
            by other means; information collected via promotional offers; or via
            any other means. Terms not defined in context have the meanings
            ascribed to them in the Definitions section of the Terms and
            Conditions. BACHAT BAAZI is committed to ensuring that your privacy
            is protected. You can be assured that information that can help
            identify you provided via any means when using this App will only be
            used in accordance with this privacy statement.
          </p>
          <p>
            This policy does not apply to the practices of organizations that we
            do not own or to people that we do not employ or manage.
          </p>
          <p>
            You may use BACHAT BAAZI website and the mobile app as a guest user
            without registering on our platform. When you use our services as a
            guest user we do not store any personal information. However, we do
            store non-personal information such as IP address, browser and
            device information.
          </p>
          <p>
            You agree and acknowledge that personal information is collected
            from you when you register as a user on BACHAT BAAZI and by
            registering as a user you provide your consent for us to do so.
          </p>
        </section>

        <section className="privacy_sections">
          <div>
            <h3 className="privacy_heading">2. Information Collected</h3>
          </div>
          <ol>
            <li>
              In order to identify you (a registered user) we process and
              collect the information including but not limited to Name, phone
              number, email address, gender, address, device identification
              number (IMEI), contact list stored on your device or other sources
              to which you provide access to and any other details which we may
              request from time to time.
            </li>
            <li>
              We use analytics tools including but not limited to google
              analytics and may also collect the standard app usage statistic
              such as the pages visited, the links you click on the mobile App
              / Website, the number of times you access a page, the number of
              times you have interacted on this App / website, amount of time
              spent by you on our website, your overall interactions with the
              app including but not limited to, number of times you buy bid
              packs, number of auction bidding tokens used, number of bids placed by you,
              number of times you win the auction, Internet Protocol Address (IP
              Address), browser information and other logs. We also may collect
              & verify information about you from a variety of sources,
              including without limitation, computer systems; third-party
              sources, and other suppliers of information; and
              consumer-reporting agencies. You consent to our collection,
              transfer and storage of information by computers or other transfer
              or storage devices anywhere across the globe.
            </li>
            <li>
              We ensure that the sensitive information such as your account
              details, credit or debit card information is not stored on our
              platform. Address such as billing address and delivery address are
              store on our platform.
            </li>
          </ol>
        </section>
        <section className="privacy_sections">
          <div>
            <h3 className="privacy_heading">3. Use Of Data</h3>
            <p>
              Your personal data is used primarily to provide you with a best in
              class user experience and smooth operations of the day to day
              business including:
            </p>
            <ol>
              <li>Verification of your identity</li>
              <li>Product delivery</li>
              <li>Provide customer support, resolve issues and grievances</li>
              <li>
                Prevent fraud, illegal activities and enforce our terms and
                conditions
              </li>
              <li>
                Communications related to your transactions within the app
              </li>
              <li>Targeted marketing, promotional offers, service updates</li>
              <li>
                Disclose Users information to its agents under a strict code of
                confidentiality.
              </li>
              <li>
                Disclose User Information to such third parties to whom it
                transfers its rights and duties under the customer agreement
                entered with the Users. In such an event, the said third parties
                use of the information will be subject to such confidentiality
                obligations as contained in this Policy.
              </li>
              <li>
                Disclose Users information to any member of its related or group
                companies including its subsidiaries, its ultimate holding
                company and its subsidiaries
              </li>
              <li>
                Respond to court orders and legal investigations (if applicable)
              </li>
              <li>Enhance your consumer experience</li>
              <li>
                Joint marketing with other affiliate partners (if applicable)
              </li>
              <li>
                Third-party advertising companies and/or ad agencies to serve
                ads when You visit Our Web site/App. These companies may use
                information about Your visits to provide advertisements on this
                site and other sites about goods and services that may be of
                interest to You. These sites may have different privacy
                practices and We encourage You to read their privacy policies.
              </li>
              <p>
                By accepting the terms of this policy, you specifically agree
                that BACHAT BAAZI and its affiliates, may access, use, and store
                your personal information for identification verification
                purposes and provision of products and services
              </p>
            </ol>
          </div>
        </section>

        <section className="privacy_sections">
          <div>
            <h3 className="privacy_heading">4. Security Of Your Information</h3>
            <p>
              To help protect your personal information from unauthorized access
              and use, we endeavour to use best effort security measures. These
              measures can include physical, electronic, and procedural
              safeguards such as computer safeguards, encrypted communication,
              and secured files and buildings. Your account is protected by a
              one time password so that only you have access to this
              information. BACHAT BAAZI will never ask for your OTP via email or
              via phone. Any loss, financial or otherwise, resulting from your
              negligence will not be the liability of BACHAT BAAZI and you agree
              to not hold us liable for any loss arising out of such negligence
              and / or breach.
            </p>
          </div>
        </section>

        <section className="privacy_sections">
          <div>
            <h3 className="privacy_heading">5. Miscellaneous</h3>
            <ol>
              <li>
                <strong>Cookies.</strong> We use cookies to compile aggregate
                data about site traffic and site interaction so that we can
                offer better site experiences and tools in the future. Cookies
                are small files that a site or its service provider transfers to
                your computer's hard drive through your Web browser (if you
                allow) that enables the sites or service provider systems to
                recognize your browser and capture and remember certain
                information.
              </li>
              <li>
                <strong>Children's Privacy.</strong> The BACHAT BAAZI
                Website/App is not directed at persons under the age of 18.
                BACHAT BAAZI does not knowingly collect or maintain information
                from persons under the age of 18.
              </li>
            </ol>
          </div>
        </section>

        <section className="privacy_sections">
          <div>
            <h3 className="privacy_heading">6. Changes To The Policy</h3>
            <p>
              From time to time, we may need to update this statement because of
              a change in the BACHAT BAAZI functionality or in applicable laws.
              BACHAT BAAZI reserves the right to update its privacy policy at
              any time without any notice to you.
            </p>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
