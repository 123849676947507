import React, { useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";
import Helmet from "react-helmet";
import { faq_data } from "../faq";
import ReactHtmlParser from "react-html-parser";
import faqimage from "../images/faq-group.png";
import "../styles/FAQ.css";
import Footer from "../components/commonComponents/Footer";

function FAQ() {
  const [activeTab, setActiveTab] = useState(0);
  
  const getTabs = () => {
    return faq_data.map((item, index) => (
      <li
        className={`${activeTab === index ? 'active' : ''}`}
        key={item.category}
        onClick={() => setActiveTab(index)}
      >
        { item.category}
      </li>
    ))
  };

  return (
    <>
      <Helmet>
        <title>
          Frequently Asked Questions - Bachat Baazi | Group Buy Shopping App
        </title>

        <meta
          name="description"
          content="How to play Bachat Baazi? How to win Bachat Baazi auctions? What are Bachat Baazi 
            tokens?"
        />

        <meta
          name="keywords"
          content="Bachat Baazi auction India, BachatBaazi, How to play Bachat Baazi, 
            How to participate in auction, Winning in auctions, winning in penny auctions, 
            penny auctions real, safe auction, trustworthy brand, trustworthy auctions, real players, 
            safety guaranteed, Bidding auctions in India, Penny auction India, Fair auctions in India, 
            best auctions in India, Most famous auctions in India, Bidding, Bidding for products, 
            bidding for lifestyle products, Bidding contest, Bidding game India, Bidding app, 
            Auction cars, Auction app India, Auction bid, auction token, Auction amount, 
            Online shopping sites in India, trustworthy auction, fair auction, real auction, 
            e-auction, online auction, winning auction, bidding auctions, bidding auctions in India,
             questions on auctions, customer support auction India, customer support Bachat Baazi"
        />
      </Helmet>
      <div className="faq_outer">
        <h1 className="faq_title">Frequently Asked Questions</h1>
        <div className="faq_tabs">
          <ul>
            {getTabs()}
          </ul>
        </div>
        <div className="split">
          <div className="faq">
            <FAQAccordian data={faq_data[activeTab].faqs} />
          </div>
          <div className="faq_image">
            <img src={faqimage} alt="faqimage"></img>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

function FAQAccordian({ data }){
  
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleClick = ( index ) => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <Accordion>
      {data.map((item, index) => (
        <>
          <Accordion.Title
            className="faq_question"
            active={activeIndex === index}
            index={index}
            onClick={ () => handleClick(index)}
          >
            <Icon
              name={
                activeIndex === index ? "angle down" : "angle right"
              }
            />
            {item.question}
          </Accordion.Title>
          <Accordion.Content
            active={activeIndex === index}
            className="faq_answer"
          >
            <p>{ReactHtmlParser(item.answer)}</p>
          </Accordion.Content>
        </>
      ))}
    </Accordion>
  );
}
export default FAQ;
