export const faq_data = [
  {
    category: 'General',
    faqs: [
      {
        question: "What is Bachat Baazi?",
        answer:
          "Bachat Baazi is a group buying platform that helps you get closer to your favourite products at a fraction of the cost. Just choose from our catalogue of products and join the group. You can also invite your friends to join with you!",
      },
      {
        question: "Why is buying through Bachat Baazi better than other platforms?",
        answer:
          "Bachat Baazi is India's most trusted group buy application, with opportunities of buying brand new products at better deals than all other platforms! Unlike other platforms in which multiple middlemen exist, Bachat Baazi works directly with manufacturers, brands and authorized resellers to ensure the best deal possible. We have a robust customer support team to support you every step of the way. Unlike other platforms, we offer proper product delivery without levying any extra delivery charges. When you shop on Bachat Baazi, you don't have to worry about anything else other than finding your favourite product.",
      },
      {
        question: "Can I invite my friends?",
        answer:
          "The more, the merrier! You can invite your friends to register and join Bachat Baazi. Inviting your friends ensures that the group completes quicker, thus taking you closer to your favourite products!",
      },
      {
        question: "What if the app crashes?",
        answer:
          "We take the quality and performance of our platform very seriously. All our features are thoroughly tested before they are released. If you still experience any technical issues, you can reach out to our customer support and they will be able to assist you.",
      },
      {
        question: "How do I contact the Bachat Baazi support team?",
        answer:
          "If you have any issues or concerns related to the app, you can write to us at support@bachatbaazi.com or reach out at the number provided in the app. We will be more than happy to address all your concerns.",
      }
    ]
  },
  {
    category: 'Group Buy',
    faqs: [
      {
        question: "How do I join a group on Bachat Baazi?",
        answer:
          "<ol><li>Download the Bachat Baazi app and sign up using your phone number</li><li>Join an ongoing group or wishlist a group you want to be notified about.</li><li>Join the group by making the payment for the product (at the discounted price).</li><li>If the group completes within the time limit, all orders will be shipped to the buyers in a few days! If the group is not able to complete, we will refund all payments within 48 hours.</li></ol>",
      },
      {
        question: "How long does a group last?",
        answer:
          "The duration of the group varies according to each product. A group ends when the number of members complete for the group. You can check the time remaining for each group on the Bachat Baazi App."
      },
      {
        question: "I just joined a group, what happens now?",
        answer:
          "Congratulations on purchasing the product at an amazing price! All you need to do now is share the product with your family and friends! As more people join the group with you, the group will fill up faster! As soon as the minimum of buyers is reached, your brand new product will be delivered to your doorsteps in a few days."
      },
      {
        question: "What if I join a group and don't pay for it?",
        answer:
          "Payments for all products must be made at the time of joining the group. If you do not make the payment for the product within the group’s time limit, you will lose out on the amazing offer.",
      },
      {
        question: "What happens if I join the group but change my mind and do not want to buy the product anymore?",
        answer:
          "In case you change your mind after making the payment for a group, please contact the support team for cancellation of your order. At the moment, it is not possible to cancel your order from the app. You can track the status of your order from the Group Buy Orders tab."
      },
      {
        question: "What happens if the group does not complete on time?",
        answer:
          "In the rare case if a group does not complete on time, we will issue a refund to your original payment method within a couple of days."
      },
      {
        question: "Can I be reminded of the start of a group for the products that I like?",
        answer:
          "Yes. Just wishlist the item and we will notify you when the product’s group opens."
      },
      {
        question: "What are Bachat Baazi auction bidding tokens?",
        answer:
          "Bachat Baazi auction bidding tokens may be used to participate in auctions. There are three ways to earn Bachat Baazi auction bidding tokens: <ol><li>When a ‘group buy’ order is successful, you will earn BB auction bidding tokens depending on the price of the product. Visit the product page for more details.</li><li> Invite your friends using your invite code. You will receive referral BB auction bidding tokens for each friend that joins!.</li><li>Top up your auction bidding tokens balance by purchasing a pack from the Bachat Baazi App.</li></ol>"
      },
      ,
    ]
  },
  {
    category: 'Order',
    faqs: [
      {
        question: "What should I do if I receive a faulty product?",
        answer:
          "Bachat Baazi Customer Care team is always there to help you with any assistance that you may need. The chances of you receiving a faulty product are minimal as we procure the products from trusted manufacturers and authorized dealers. However, on the rare occasion that such an event occurs, you can reach out to us and our Customer Care Executives will guide you through the process.",
      },
      {
        question: "Can the product be tracked in terms of delivery?",
        answer:
          "Yes, our products are fulfilled by our delivery partners. You will receive regular updates on your product delivery status. You can also reach out to us on support@bachatbaazi.com if you need any assistance on the same. We process all orders within 2 days from the date of placing the order.",
      },
      {
        question: "Can I return goods?",
        answer: "Please first read our returns policy and our refunds policy. If you wish to return a product after it has been delivered, please contact our customer support team at support@bachatbaazi.com or reach out at the number given in the app",
      },
      {
        question: "What are the Shipping or Delivery charges?",
        answer:
          "All products are delivered to your doorsteps at no additional cost.",
      },
      {
        question: "Can I cancel my purchase?",
        answer: "Please first read our cancellation policy. In case you change your mind and wish to cancel your order, please contact our customer support team at support@bachatbaazi.com or reach out at the number given in the app.",
      },
      {
        question: "What happens if the item is not in stock?",
        answer:
          "We maintain sufficient inventory of the stock, however on the rare occasion when this happens due to circumstances outside our control, our team will reach out to you to discuss the alternative options including a refund.",
      },
    ]
  },
  {
    category: 'Rules',
    faqs: [
      {
        question: "Can I open more than one account on Bachat Baazi?",
        answer:
          "No, you can only open one account on Bachat Baazi. Hosting multiple accounts by a single user is considered as a breach of the agreement that the user is bound to while accepting the terms and conditions. This will also be considered as an illegal practice. If any user is found operating multiple accounts, Bachat Baazi will reserve the right to suspend the account. Their number, email ID and other contact details will also be barred from registering or interacting with the platform. If an order is to be processed and the user is found to have multiple accounts, then the refund will be processed equal to the product value of the order to one account.",
      },
      {
        question: "Can I resell the products in my local markets?",
        answer:
          "Products bought at Bachat Baazi are not meant for reselling or giveaways.",
      },
      {
        question: "When do I have to make the payment for joining a group?",
        answer:
          "Payments for joining any group must be made at the same time. An order will not be considered as successful until the payment has been made.",
      },
      {
        question: "What is prohibited while shopping on Bachat Baazi?",
        answer:
          "We do not encourage any unethical practices by any user while participating in our bids. Any unethical practices spotted by us would force us to take a legal action. 1) Using multiple devices by a single user to place bids is prohibited 2) Creating multiple accounts by one user is prohibited 3) All users must register with their legal names while registering.",
      },
    ]
  },
  {
    category: 'Payment',
    faqs: [
      {
        question: "What payment methods are available? Is my payment safe?",
        answer:
          "All payments on Bachat Baazi are processed using our payment gateway partners. All payment modes including credit card, debit card, net-banking, UPI are available on Bachat Baazi. Our payment gateway partners are fully protected and PCI compliant thereby ensuring that your payment details are safe, secured and confidential.",
      },
      {
        question: "Can I get a refund for Bachat Baazi auction bidding tokens which I have purchased?",
        answer:
          "No, the Bachat Baazi auction bidding tokens that you have purchased cannot be refunded. You can utilize them in our product auctions. In case of any issue regarding auction bidding tokens purchased, you can get in touch with our Customer Care. We will try and find the best possible solution for you.",
      },
      // {
      //   question:
      //     "What happens to my money if I don’t make it to a private auction?",
      //   answer:
      //     "No, the Bachat Baazi tokens that you have purchased cannot be refunded. You can utilize them to play games and win bonus products. In case of any issue regarding tokens purchased, you can get in touch with our Customer Care. We will find the best possible solution for you.",
      // },
      {
        question: "Are my details safe?",
        answer:
          "Yes, your details are safe and protected with us. We comply with the data protection regulations, and we take utmost care to keep your details safe and secured. Any information will be used only with your permission and will be solely utilized for making the Bachat Baazi application better for your benefit.",
      }
    ]
  },
  // {
  //   category: 'Legal',
  //   faqs: [
  //     {
  //       question:
  //         "Is it legal to offer such online auction services and to participate in them?",
  //       answer:
  //         "Yes, participating and hosting online auctions is legal in India. The terms and conditions are mentioned in a detailed manner and by accepting these T&C, the buyer agrees to purchase a product or a service in lieu of a pre-defined payment, which makes the offers provided by online auction services into an agreement between the buyer and the auction service entity. Since all the products and purchases are within the boundaries of a valid contract, online auctions are considered as legal contracts and participating in them adhering to the agreement is considered as legal.",
  //     },
  //     {
  //       question: "Is this a scam?",
  //       answer:
  //         "<span class='faq_colored'>Bachat Baazi is a community that believes in transparency and responsible gaming. Winners' identities are made available to the community via our website (after due consent from them), and our help desks remain active to address any queries that the players may have from time to time. All transaction history - including bid packs earned/purchased, games played, and prizes won are available at the click of a button in the app. Our games are designed to be time-efficient, with rules clearly outlined to prevent any confusion while playing.</span>"
  //     },
  //     {
  //       question: "What are the signs of rigged auctions?",
  //       answer:
  //         "<span class='faq_colored'>Some instances of rigged auction can include the same persons winning a disproportionate number of games, multiple identities being created by hackers, switching of products at delivery and winners being determined by the time of a bid, as opposed to the amount contributed in the auction. We have strict policies against rigged auctions, and as a part of compliance, we do not allow our employees to participate in Bachat Baazi bids</span>",
  //     },
  //   ]
  // }
];