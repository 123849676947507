import React from "react";
import Googleplay from "./Googleplay";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react"
import "../../styles/commonComponentsStyles/Footer.css";

const Footer = () => {
  return (
    <div id="footer">
      <div className="footer_row_1">
      <div id="footer-address">
        <h4 className="footer-heading">Company</h4>
        <p>
          Revmax Technologies Private Limited
          {/* , 697, Shahabad Daulatpur, Near
          DTU, <br/>DELHI - 110042 */}
        </p>
      </div>
      <div id="footer-contact">
        <h4 id="contact-heading" className="footer-heading">
          Contact Details
        </h4>
        <a href="mailto: support@bachatbaazi.com" style={{wordBreak:"break-all"}}>support@bachatbaazi.com</a>
        <span className="contact-d"><Icon name="whatsapp"/>+91 93206 56293</span>
      </div>
      </div>
      <div className="footer_row_2">
      <div id="footer-list">
        <Link to="/termsandconditions">
          <p>Terms &amp; Conditions</p>
        </Link>
        <Link to="/privacypolicy">
          <p>Privacy Policy</p>
        </Link>
        <Link to="/cancellationpolicy">
          <p>Cancellation Policy</p>
        </Link>
        <Link to="/refunds">
          <p>Refunds</p>
        </Link>
        <Link to="/returns">
          <p>Returns</p>
        </Link>
        {/* <p>Help & Support</p> */}
      </div>
      <div id="play-text">
        <p>Download in Google playstore</p>
        <Googleplay />
      </div>
      </div>
    </div>
  );
};

export default Footer;
