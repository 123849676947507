import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import Footer from '../components/commonComponents/Footer';
import refundsImgae from '../images/refunds.png';

import '../styles/Returns.css'

function Returns(){

  return (
    <Fragment>
      <Helmet>
        <title>Refunds - Bachat Baazi | Group Buy Shopping App</title>
        <meta
          name="description"
          content="How to play Bachat Baazi? How to win Bachat Baazi auctions? What are Bachat Baazi 
          tokens? What are the rules of playing Bachat Baazi auction shopping game"
        />

        <meta
          name="keywords"
          content="Bachat Baazi auction India, BachatBaazi, How to play Bachat Baazi,
           How to participate in auction, Bachat Baazi game terms and conditions, Bachat Baazi rules, 
           Bachat baazi eligibility, Bachat Baazi rules of conduct, Bachat Baazi auction and site rules, 
           Bachat Baazi intellectual property and ownership, Bachat Baazi fees and payment, 
           Bachat Baazi shipping and delivery charges, Bachat Baazi disclaimers and warranty, 
           Bachat Baazi refund, Bachat Baazi return, Bachat Baazi Legal, Bachat Baazi Agreement, 
           Bachat Baazi Legality, Winning in auctions, winning in penny auctions, penny auctions real, 
           safe auction, trustworthy brand, trustworthy auctions, real players, safety guaranteed, 
           Bidding auctions in India, Penny auction India, Fair auctions in India, best auctions in India,
            Most famous auctions in India, Bidding, Bidding for products, bidding for lifestyle products, 
            Bidding contest, Bidding game India, Bidding app, Auction cars, Auction app India, Auction bid,
             auction token, Auction amount, e-auction legal in India, auction game legal in India, 
             online auction, winning auction, bidding auctions, bidding auctions in India,"
        />
      </Helmet>
      <div className="returns-outer">
        <h1>
          Refunds
        </h1>
        <div className="split">
          <div className="returns">
          <ul>
            <li>
              <p>
                Once We receive the item/ shipment back at Bachat Baazi, We initiate the process of refund within 2 (two) business days.
            </p>
              <ul>
                <li>
                  <p>
                    <strong>Electronic payment</strong> (credit card, debit card or net banking) refunds are processed within [2-4 business days] whereby you will need to update following information to enable Us to process a refund to your account:
                </p>
                  <ul>
                    <li>Bank Account Number</li>
                    <li>IFSC Code</li>
                    <li>Account Holder's Name</li>
                    <li>Bank Branch</li>
                    <li>Bank Name</li>
                  </ul>
                </li>
                <li>
                  <strong>Mixed Payment</strong> (credit card, gift cards or credit points) – The refund amount shall be paid through the same mode of payment. The gift card amount shall be added to your account under auction bidding tokens and can be used towards future auctions.
              </li>
              </ul>
            </li>
            <li>
              <strong>Fine Print</strong>
              <ul>
                <li>Refunds to happen through the same mode by which the payment was made, however, you may want to request for a change to credit/reward points.</li>
                <li>If your refund doesn't appear in ‘Your Account’, and the processing time for your payment method has passed, contact Us for further assistance.</li>
              </ul>
            </li>
            <li>
              <p><strong>Expired Payment Method</strong></p>
              <p>
                If the original payment method you used to make the payment (credit/ debit card) is no longer valid, contact Us for further assistance.
            </p>
            </li>
            <li>
              <p><strong>No Cancellation or Refund on Electronic Goods</strong></p>
              <p>
              Electronic goods for instance e-Gift cards and auction bidding tokens once purchased cannot be cancelled or returned due to regulatory issues. If you have an exceptional circumstance due to which you require a refund, please contact us.
            </p>
            </li>
          </ul>
          </div>
          <div className="returns-image">
            <img src={refundsImgae} alt="refunds"/>
          </div>
        </div>
      </div>
      <Footer/>
    </Fragment>
  );
}

export default Returns;