import React from "react";
import Helmet from 'react-helmet';
import Header from "../components/homePageComponents/Header";
import { Link } from 'react-router-dom';
import Footer from "../components/commonComponents/Footer";
import useFetchBulkGroupBuyProducts from '../hooks/useFetchBulkGroupBuyProducts';
import useFetchBulkAuctions from "../hooks/useFechBulkAuctions";
import GroupBuyProductCard from '../components/homePageComponents/GroupBuyProductCard';
import AuctionCard from '../components/homePageComponents/AuctionCard';

import '../styles/Home.css'
import { Image } from "semantic-ui-react";
import mobileCart from '../images/mobile-cart.png';
import discountImg from '../images/discount.png';
import noFeeImg from '../images/no-fee.png';
import secureImg from '../images/secure.png';
import ratingImg from '../images/rating.png';
import referImg from '../images/refer.png';

const openAppLink = () => {
  window.open('https://play.google.com/store/apps/details?id=com.bachatbaazi&utm_source=website&utm_medium=website', '_blank')
}

const HomePage = () => {

  return (
    <>
      <Helmet>
        <title>Bachat Baazi - Save more by shopping in group</title>

        <meta
          name="description"
          content="How to play Bachat Baazi? How to win Bachat Baazi auctions?
           What are Bachat Baazi tokens? What are the rules of playing Bachat Baazi auction shopping game"/>

        <meta
          name="keywords"
          content="Bachat Baazi auction India, BachatBaazi, How to play Bachat Baazi, 
          How to participate in auction, Bachat Baazi game terms and conditions, Bachat Baazi rules, 
          Bachat baazi eligibility, Bachat Baazi rules of conduct, Bachat Baazi auction and site rules, 
          Bachat Baazi intellectual property and ownership, Bachat Baazi fees and payment, 
          Bachat Baazi shipping and delivery charges, Bachat Baazi disclaimers and warranty, 
          Bachat Baazi refund, Bachat Baazi return, Bachat Baazi Legal, Bachat Baazi Agreement, 
          Bachat Baazi Legality, Winning in auctions, winning in penny auctions, penny auctions real, 
          safe auction, trustworthy brand, trustworthyauctions, real players, safety guaranteed, 
          Bidding auctions in India, Penny auction India, Fair auctions in India, best auctions in India, 
          Most famous auctions in India, Bidding, Bidding for products, bidding for lifestyle products, 
          Bidding contest, Bidding game India, Bidding app, Auction cars, Auction app India, Auction bid, 
          auction token, Auction amount, e-auction legal in India, auction game legal in India, 
          online auction, winning auction, bidding auctions, bidding auctions in India,"
        />
      </Helmet>

      <section className="download-app-container">
        <div className="text-container">
          <h1><span style={{ color: '#FF8A00' }}>Bachat</span> <span style={{ color: '#7C00C8' }}>Baazi</span></h1>
          <h3>Where shopping meets thrills!</h3>
          <p> Transform shopping into a thrilling experience... in an instant.</p>
          <button onClick={() => openAppLink()}>Download</button>
        </div>
        {/* <Image style={{ height: 650, width: 414 }} src={mobileCart} /> */}
      </section>

      <section className="what-is-bachatbaazi-contianer">
        <h4>What is Bachat Baazi?</h4>
        <div className="answer">
          <p>Bachat Baazi is fast becoming India's most preferred shopping destination. It enables you to buy your favourite products below market prices. Unlike other ecommerce platforms that promote offers only on special days, the deals and discounts on Bachat Baazi are available throughout the year!</p>
        </div>
      </section>

      <section className="cards-container">
        <div className="cards-floating-container">
          <div className="cards">
            <Image
              style={{ width: 96, height: 96, marginTop: 30, marginBottom: 30, marginLeft: 'auto', marginRight: 'auto' }}
              src={discountImg}
            />
            <p className="heading">Heavy discounts</p>
            <p className="description">Buy your favourite products at heavy discounts</p>
          </div>
          <div className="cards">
            <Image
              style={{ width: 96, height: 96, marginTop: 30, marginBottom: 30, marginLeft: 'auto', marginRight: 'auto' }}
              src={noFeeImg}
            />
            <p className="heading">No additional charges</p>
            <p className="description">Pay no additional charges, no handling fee, no delivery charges</p>
          </div>
          <div className="cards">
            <Image
              style={{ width: 96, height: 96, marginTop: 30, marginBottom: 30, marginLeft: 'auto', marginRight: 'auto' }}
              src={secureImg}
            />
            <p className="heading">Safe & secure</p>
            <p className="description">We follow the highest security protocols and work with industry-leading partners</p>
          </div>
          <div className="cards">
            <Image
              style={{ width: 96, height: 96, marginTop: 30, marginBottom: 30, marginLeft: 'auto', marginRight: 'auto' }}
              src={ratingImg}
            />
            <p className="heading">Strong user satisfaction</p>
            <p className="description">Play Store rating 4.4/5 as of 14 Nov 2023</p>
          </div>
          <div className="cards">
            <Image
              style={{ width: 96, height: 96, marginTop: 30, marginBottom: 30, marginLeft: 'auto', marginRight: 'auto' }}
              src={referImg}
            />
            <p className="heading">Refer and earn</p>
            <p className="description">Refer your friends and family for additional benefits</p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default HomePage;
