import React, { useState } from 'react';
import { BBUPLOADS_URI } from '../../config';
import { Link } from 'react-router-dom';
import InstallAppModal from '../modals/InstallAppModal';
import Countdown from '../Countdown';
import useGetHighestBidders from '../../hooks/useGetHighestBidders';

import '../../styles/homeComponentsStyles/GroupBuyProductCard.css';

function AuctionCard({ id, title, avatar, discountedPrice, price, views, expiresAt, product  }){
  const [isModelOpen, setModelOpen] = useState(false);
  const {isLoading, data:highestBidderData, error:highestBidderError} = useGetHighestBidders(product);

  return (
    <>
    <article className="groupbuy-product-card">
      <Link to={`/auctions`}>
        <h2 className="groupbuy-product-card-title">
          { title }
        </h2>
        <img 
          src={`${BBUPLOADS_URI}/${avatar}`} 
          alt="product" 
        />
        <div className="groupbuy-product-card-price">
          Price:
          <span className="new-price">Rs. {discountedPrice}</span>
          <span className="old-price">{price}</span>
        </div>
        <p>{`${views} views`}</p>
      </Link>
      <div className='past-winners-container'>
        <h3 style={{margin: 0, fontSize: '15px'}}>Past winners</h3>
        <table className='past-winners-list'>
          <tr>
            <th>Name</th>
            <th>Final Bid</th>
            <th>Total Bids</th>
            <th>Date and Time</th>
          </tr>
          {
            isLoading? 'Loading past winners': highestBidderData["_items"].slice(0, 10).map((bidder) => (
              <tr key={bidder.id}>
                <td>{bidder.highestBidder || '-'}</td>
                <td>RS. {bidder.wprice}</td>
                <td>{bidder.nbids}</td>
                <td>{`${new Date(bidder._updated).toLocaleDateString()} ${new Date(bidder._updated).toLocaleTimeString()}`}</td>
              </tr>
              // <li className='past-winners-item' key={bidder.id}>
              //   <b>Rs. {bidder.wprice}</b>
              //   <span>{bidder.highestBidder}</span>
              // </li>
            ))
          }
        </table>
      </div>
      <div className="groupbuy-product-card-footer">
        <div className="countdown-container">
          <span>Closes in</span>
            {/* {expiresAt} */}
          <Countdown date={expiresAt} />
        </div>
        <a href="javascript:void(0);" className="join-btn-card" onClick={() => setModelOpen(true)}>Bid Now</a>
      </div>
    </article>
    <InstallAppModal open={isModelOpen} onClose={() => setModelOpen(false)}>
      To Bid on this Auction, please install the app clicking below and start saving 
    </InstallAppModal>
      {/* <UserDetailsForm showModal={isModelOpen} setShowModal={() => setModelOpen(false)} /> */}
    </>
  )
}

export default AuctionCard;